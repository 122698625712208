<div>
  <mat-accordion>
    <mat-expansion-panel id="users-panel" [expanded]="showFilter" (opened)="showFilter = true"
                         (closed)="showFilter = false">
      <mat-expansion-panel-header>
        <mat-panel-title>User Management</mat-panel-title>
        <mat-panel-description>User Search - Click to Toggle Search Criteria</mat-panel-description>
      </mat-expansion-panel-header>

      <br>

      <div>
        <form [formGroup]="filterForm">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Select Status</mat-label>
                <mat-select id="filter-status" [multiple]="true" [formControl]="filterStatusFormCtl">
                  <mat-option *ngFor="let status of statuses"
                              [value]="status.enabled">{{ status.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row" class="bottom-bar-right">
            <div class="col-md-12">
              <button mat-stroked-button (click)="newUser();">New User</button>
              |
              <button mat-stroked-button color="warn" (click)="resetFilter();">Reset</button>
              <button mat-stroked-button (click)="hideFilter();">Cancel</button>
              <button mat-stroked-button (click)="doFilter()" class="ae-bold ae-search">
                <span class="ae-search">Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<!------------ start new user ---------------->
<mat-card class="card-padded" *ngIf="inEdit === true">
  <mat-card-content>
    <div class="row">
      <div class="col-md-6" style="text-align: left">{{ userFormTitle }}</div>
      <div class="col-md-6" style="text-align: right;">
        <button *ngIf="currentUser" mat-stroked-button (click)="doneEditing();">
          <span style="font-weight: bold;">Done</span>
        </button>
      </div>
    </div>

    <form [formGroup]="userForm" autocomplete="off" *ngIf="inEdit === true">
      <div class="row" style="padding-top: 20px">
        <div class="col-md-6">
          <mat-form-field [style.width.px]=400>
            <mat-label>Username (email)</mat-label>
            <input name="fem" matInput autocomplete="off" required="true" placeholder="User Email Address"
                   [formControl]="userNameFormCtl" email>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-label>User Enabled</mat-label>
          <mat-checkbox id="user-enabled" [checked]="this.userEnabledFormCtl.value"
                        (change)="changeStatus($event.checked)"></mat-checkbox>
        </div>
      </div>
      <div class="row" class="bottom-bar-right" *ngIf="userIsNewCtl.value">
        <div class="col-md-12">
          <button mat-stroked-button (click)="cancelEdit();">Cancel</button>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <button mat-stroked-button color="primary" [disabled]="!canSubmit()"
                  (click)="createUser()">Create
          </button>
        </div>
      </div>
    </form>
    <div class="row" *ngIf="!userIsNewCtl.value">
      <div class="col-md-12">
        <table class="table table-sm">
          <thead>
          <tr>
            <th class="pivot-table">Source Role / Dataset </th>
            <th class="pivot-table centered" *ngFor="let dataset of availableDatasets">{{ formatDataset(dataset.name) }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sourceRole of availableSourceRoles">
            <td class="pivot-table">{{sourceRole}}</td>
            <td class="centered" *ngFor="let dataset of availableDatasets">
              <mat-checkbox id="user-source-role" [checked]="hasSourceRole(sourceRole, dataset.name)"
                            (change)="changeSourceRole($event.checked, sourceRole, dataset.name)"></mat-checkbox>
<!--              <mat-label>{{ sourceRole }} - {{dataset.name}}}</mat-label>-->
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-error class="form-error" *ngFor="let message of formErrors">
          Error:&nbsp;{{ message }}
        </mat-error>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<!-------------end new user------------------->

<div #usersTop class="search-filter" *ngIf="!inEdit">
  <mat-card *ngIf="users && users.length > 0">
    <div class="header-menu">
      <div class="header-menu-title"></div>
      <button *ngIf="currentUser" mat-stroked-button (click)="clearCurrent();">
        <span style="font-weight: bold">Clear Selection</span>
      </button>
    </div>

    <div class="scroll-pane scroll-pane-all">
      <table class="table table-sm table-hover table-striped">
        <thead>
        <tr>
          <th>Email</th>
          <th>Enabled</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let u of users; index as i" #usersList
            [ngClass]="{'selected': currentUserId == u.id}" class="link-active">

          <td (click)="currentUserId == u.id ? clearCurrent() : selectUser(u.id, i)">{{ u.email }}</td>
          <td (click)="currentUserId == u.id ? clearCurrent() : selectUser(u.id, i)">{{ formatYesNo(u?.enabled) }}</td>

        </tr>
        </tbody>
      </table>
    </div>
  </mat-card>
  <mat-card *ngIf="users && users.length === 0">
    <mat-card-content>
      No Matching Users
    </mat-card-content>
  </mat-card>


</div>
